import React from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import 'whatwg-fetch';

smoothscroll.polyfill();

const container = document.getElementById('root');

export const renderApp = AppComponent => {
  const root = createRoot(container);
  root.render(<AppComponent />);
};
